import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
  DeleteLinksRequestType,
  GetLinksListResponseType,
  LinkInitialStateType,
} from "../types/linkType";
import linkRequests from "../api/linkRequests";
import { RootState } from ".";

export const getLinks = createAsyncThunk<
  GetLinksListResponseType,
  string,
  { rejectValue: Error | AxiosError; state: RootState }
>("links/getLinks", async (categoryFIlter, { rejectWithValue, getState }) => {
  try {
    const pageNumber = getState().link.page;
    const response = await linkRequests.getLInks(categoryFIlter, pageNumber);
    return response.data;
  } catch (error: any) {
    console.log(error);
    return rejectWithValue(error);
  }
});

export const searchLinks = createAsyncThunk<
  GetLinksListResponseType,
  string,
  { rejectValue: Error | AxiosError }
>("links/searchLinks", async (searchFieldData, { rejectWithValue }) => {
  try {
    const response = await linkRequests.searchLinks(searchFieldData);
    return response.data;
  } catch (error: any) {
    console.log(error);
    return rejectWithValue(error);
  }
});

export const deleteLinks = createAsyncThunk<
  GetLinksListResponseType,
  DeleteLinksRequestType,
  { rejectValue: Error | AxiosError }
>(
  "links/deleteSelectedLinks",
  async (deleteLinksRequestData, { rejectWithValue }) => {
    if (!deleteLinksRequestData.selectedLinksIds.length)
      throw new Error("Please provide deletedLinks data");
    try {
      const response = await linkRequests.deleteLinks(deleteLinksRequestData);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

const initialState: LinkInitialStateType = {
  links: [],
  pagesCount: 1,
  hasNext: false,
  hasPrevious: false,
  page: 1,
  categoryFilter: "",
  selectedCount: 0,
};

const linkSlice = createSlice({
  name: "link",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(getLinks.fulfilled, (state, action) => {
      state.links = action.payload.links;
      state.page = action.payload.page;
      state.hasNext = action.payload.hasNext;
      state.hasPrevious = action.payload.hasPrevious;
      state.pagesCount = action.payload.pagesCount;
    });
    builder.addCase(deleteLinks.fulfilled, (state, action) => {
      state.links = action.payload.links;
      state.pagesCount = action.payload.pagesCount;
      state.hasNext = action.payload.hasNext;
      state.hasPrevious = action.payload.hasPrevious;
      state.page = action.payload.page;
    });
    builder.addCase(searchLinks.fulfilled, (state, action) => {
      state.links = action.payload.links;
    });
  },
  reducers: {
    selectAll: (state, action: PayloadAction<undefined>) => {
      if (state.selectedCount === state.links.length) {
        state.links.forEach((link) => (link.selected = false));
        state.selectedCount = 0;
        return;
      }
      if (state.selectedCount >= 0) {
        state.links.forEach((link) => (link.selected = true));
        state.selectedCount = state.links.length;
        return;
      }
    },

    setSelected: (state, action: PayloadAction<number>) => {
      const link = state.links.find((link) => link.id === action.payload);

      if (link) {
        if (link.selected) {
          link.selected = false;
          state.selectedCount -= 1;
          return;
        }
        if (!link.selected) {
          link.selected = true;
          state.selectedCount += 1;
          return;
        }
      }
    },
    setCategoryFilter: (state, action: PayloadAction<string>) => {
      state.categoryFilter = action.payload;
    },
  },
});

export const { selectAll, setSelected, setCategoryFilter } = linkSlice.actions;

export default linkSlice.reducer;
