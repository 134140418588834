import styled from "styled-components";

const StyledLoader = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 200px;
`;

export default StyledLoader;
