import axios from "axios";
import _history from "../const/history";

const baseURL = "https://mp-search.ru/api/v1/";

const options = {
  baseURL: baseURL,
};

const axiosIntsance = axios.create(options);

axiosIntsance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("access");
  if (accessToken) {
    config.headers.Authorization = "Bearer " + accessToken;
  }
  return config;
});

axiosIntsance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    try {
      const refreshToken: string | null = localStorage.getItem("refresh");
      if (refreshToken !== null) {
        const { response, config } = error;
        if (
          response.status === 401 &&
          response.data.error !== "Password is not valid"
        ) {
          try {
            const res = await axios.post(
              `${baseURL}user/refresh/`,
              {
                refresh: refreshToken,
              }
            );
            localStorage.setItem("access", res.data.access);

            return await axiosIntsance(config);
          } catch (error: any) {
            if (
              error.response.status === 401 &&
              error.response.data.code === "token_not_valid"
            ) {
              localStorage.removeItem("access");
              localStorage.removeItem("refresh");
              if (_history.navigate)
                _history.navigate("/login");
            }
          }
        }
      }
      return Promise.reject(error);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }
);

export default axiosIntsance;
