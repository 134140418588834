import styled from "styled-components";
import { displayFlexCss } from "../../theme/cssFragments/displatyFlexFragment";

const StyledHeader = styled.header`
  position: absolute;
  width: 100%;
  height: 75px;
  background-color: ${(props) => props.theme.colorDarkGray};

  .header__nav {
    width: 1148px;
    ${displayFlexCss}
    margin: 15px auto;
  }
  .header__link {
    margin: 0 20px 0 0;
    height: 50px;
    padding-left: 10px;
    padding-top: 15px;
    border-left: 2px solid ${(props) => props.theme.colorLightGray};
    font-size: ${(props) => props.theme.fontSize18};
    font-weight: ${(props) => props.theme.fontWeight400};
    color: ${(props) => props.theme.colorLight};
    text-decoration: none;
    cursor: pointer;
    &:visited {
      color: ${(props) => props.theme.colorLight};
      text-decoration: none;
    }
  }
  @media (max-width: 1440px) {
    .header__nav {
      width: 80%;
    }
  }
  @media (max-width: 980px) {
    height: fit-content;
    position: static;
    .header__nav {
      margin: 0;
      flex-direction: column;
      height: 210px;
      width: 100%;
    }
    .header__link {
      margin: 0 0 0 0;
      height: 300px;
      width: 100%;
      padding: 10px 0 10px 0;
      border: none;
      font-size: ${(props) => props.theme.fontSize18};
      font-weight: ${(props) => props.theme.fontWeight400};
      color: ${(props) => props.theme.colorLight};
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      &:nth-child(2n) {
        background-color: #00000029;
      }
    }
  }
`;

export default StyledHeader;
