import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import baseTheme from "../theme/baseTheme";

type NotificationInitialStateType = {
  notificationTitle: string;
  notificationText: string;
  notificationBackgroundColor: string;
  notificationTextColor: string;
};

const notificationInitialState: NotificationInitialStateType = {
  notificationTitle: "",
  notificationText: "",
  notificationBackgroundColor: baseTheme.notificationWarn,
  notificationTextColor: baseTheme.colorDarkBlue,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: notificationInitialState,
  reducers: {
    setNotification: (
      state,
      action: PayloadAction<{
        notificationTitle: string;
        notificationText: string;
        notificationBackgroundColor: string;
        notificationTextColor: string;
      }>
    ) => {
      state.notificationTitle = action.payload.notificationTitle;
      state.notificationText = action.payload.notificationText;
      state.notificationBackgroundColor =
        action.payload.notificationBackgroundColor;
      state.notificationTextColor = action.payload.notificationTextColor;
    },
  },
});

export const { setNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
