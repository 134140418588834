import React from "react";
import StyledLoader from "./Loader.style";
import { ClipLoader } from "react-spinners";

type Props = {
  color: string;
  size: number;
};

const Loader: React.FC<Props> = (props) => {
  return (
    <StyledLoader>
      <ClipLoader color={props.color} size={props.size} />
    </StyledLoader>
  );
};

export default React.memo(Loader);
