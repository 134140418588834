import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import MainLayout from "./layouts/mainLayout/MainLayout";
import { authorizationRequests } from "./api/authRequests";
import RequireAuth from "./components/commonComponents/requireAuth/RequireAuth";
import { useAppDispatch } from "./store/typedHooks";
import { setUser } from "./store/userSlice";
import { CategoryEnum } from "./types/linkType";
import Loader from "./components/commonComponents/loader/Loader";
import baseTheme from "./theme/baseTheme";
import _history from "./const/history";
import { createPortal } from "react-dom";
import background from "./assets/background.svg";

const style: React.CSSProperties = {
  width: "100%",
  height: "100%",
  position: "fixed",
  backgroundPosition: "center center",
  backgroundSize: "contain",
  top: 0,
  left: 0,
  zIndex: -1,
  backgroundImage: `url(${background})`,
};

const Login = React.lazy(() => import("./pages/login/Login"));
const LinksList = React.lazy(() => import("./pages/linksList/LinksList"));
const Cient = React.lazy(() => import("./pages/client/Client"));
const FillFormCompleted = React.lazy(
  () => import("./pages/fillFormCompleted/FillFormCompleted")
);
const LinkGenerator = React.lazy(
  () => import("./pages/linkGenerator/LinkGenerator")
);
const IncomingTrafficPageLayout = React.lazy(
  () => import("./layouts/incomingTrafficPageLayout/IncomingTrafficPageLayout")
);
const QrCodeGenerator = React.lazy(
  () => import("./pages/qrCodeGenerator/QrCodeGenerator")
);

const PrivacyPolicy = React.lazy(() => import("./pages/policy/PrivacyPolicy"));

const CookiesPolicy = React.lazy(() => import("./pages/policy/CookiesPolicy"));
const Page404 = React.lazy(() => import("./pages/404Page/Page404"));

const ErrorPage = React.lazy(() => import("./pages/errorPage/ErrorPage"));

const loader = <Loader size={89} color={baseTheme.colorPink} />;

const App: React.FC = () => {
  _history.navigate = useNavigate();

  const [isInitialized, setIsInitialized] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    (async () => {
      if (!localStorage.getItem("access")) {
        setIsInitialized(true);
        return;
      }
      try {
        const response = await authorizationRequests.getUserData();
        if (!response.data) {
          throw new Error(response.statusText);
        }
        dispatch(setUser(response.data));
        setIsInitialized(true);
      } catch (error) {
        console.log(error);
        setIsError(true);
        setIsInitialized(true);
      }
    })();
  }, []);

  if (!isInitialized) return <Loader size={80} color={baseTheme.colorPink} />;
  if (isError) return <ErrorPage />;

  return (
    <>
      <Routes>
        <Route
          path="login"
          element={
            <React.Suspense fallback={loader}>
              <Login />
            </React.Suspense>
          }
        />
        <Route
          path="/client/complete"
          element={
            <React.Suspense fallback={loader}>
              <FillFormCompleted />
            </React.Suspense>
          }
        />
        <Route
          path="/client/:uuid"
          element={
            <React.Suspense fallback={loader}>
              <Cient />
            </React.Suspense>
          }
        />
        <Route
          path="/"
          element={
            <React.Suspense fallback={loader}>
              <RequireAuth>
                <MainLayout />
              </RequireAuth>
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={loader}>
                <LinkGenerator
                  title={"Генерация ссылки для обращения клиента к подрядчику"}
                  linkSource={CategoryEnum.clientToContractor}
                />
              </React.Suspense>
            }
          />
          <Route
            path="incoming-traffic-links"
            element={<IncomingTrafficPageLayout />}
          >
            <Route
              index
              element={
                <React.Suspense fallback={loader}>
                  <LinkGenerator
                    title="Генерация клиентской ссылки для продвижения компании"
                    linkSource={CategoryEnum.clientToCompany}
                  />
                </React.Suspense>
              }
            />
            <Route
              path="partners"
              element={
                <React.Suspense fallback={loader}>
                  <LinkGenerator
                    title="Генерация партнёрской ссылки для продвижения компании"
                    linkSource={CategoryEnum.partnerToCompany}
                  />
                </React.Suspense>
              }
            />
            <Route
              path="champaigns"
              element={
                <React.Suspense fallback={loader}>
                  <LinkGenerator
                    title="Генерация ссылки для РК"
                    linkSource={CategoryEnum.advertisementCampaign}
                  />
                </React.Suspense>
              }
            />
          </Route>
          <Route
            path="links-list"
            element={
              <React.Suspense fallback={loader}>
                <RequireAuth>
                  <LinksList />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path="generate-qr-code"
            element={
              <React.Suspense fallback={loader}>
                <RequireAuth>
                  <QrCodeGenerator />
                </RequireAuth>
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path="privacy-policy"
          element={
            <React.Suspense fallback={loader}>
              <PrivacyPolicy />
            </React.Suspense>
          }
        />
        <Route
          path="coockies-policy"
          element={
            <React.Suspense fallback={loader}>
              <CookiesPolicy />
            </React.Suspense>
          }
        ></Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
      {createPortal(<div style={style}></div>, document.body)}
    </>
  );
};
export default App;
