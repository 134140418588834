import styled from "styled-components";

const StyledMainLayout = styled.div`
  width: 100%;


  .main-layout__outlet-container {
    padding-top: 100px;
  }
  @media (max-width: 980px) {
    .main-layout__outlet-container {
      padding-top: 0;
    }
}
`;

export default StyledMainLayout;
