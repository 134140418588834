import React from "react";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../../store/typedHooks";
import { userNameSelector } from "../../../store/selectors";
import baseTheme from "../../../theme/baseTheme";
import Loader from "../loader/Loader";

type Props = {
  children: React.JSX.Element;
};

export const RequireAuth: React.FC<Props> = (props) => {
  const [isChecking, setIsChecking] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const username = useAppSelector(userNameSelector);

  React.useEffect(() => {
    const access = localStorage.getItem("access");
    const refresh = localStorage.getItem("refresh");


    if (!username || (!access && !refresh)) navigate("/login");
    setIsChecking(false);
  });

  if (!props.children) {
    throw new Error("Pass children to requireAuth component");
  }

  if (isChecking) return <Loader size={80} color={baseTheme.colorDarkGray} />;
  return props.children;
};

export default RequireAuth;
