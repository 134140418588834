import { AuthType, UserType } from "../types/userType";
import axiosIntsance from "./httpCommon";

const getUserData = () => axiosIntsance.get<UserType>("user/get-user");

const login = (username: string, password: string) =>
  axiosIntsance.post<AuthType>("user/token/", {
    username,
    password,
  });

const authorizationRequests = {
  getUserData,
  login,
};

export { authorizationRequests };
