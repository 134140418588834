import React from "react";
import StyledNotification from "./Notification.style";
import { useAppDispatch, useAppSelector } from "../../../store/typedHooks";
import { selectNotification } from "../../../store/selectors";
import { setNotification } from "../../../store/notificationSlice";

const Notification: React.FC = () => {
  const notificationData = useAppSelector(selectNotification);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setTimeout(() => {
      dispatch(
        setNotification({
          notificationTitle: "",
          notificationText: "",
          notificationBackgroundColor: "",
          notificationTextColor: "",
        })
      );
    }, 2000);
  }, [notificationData]);

  if (!notificationData.notificationText) return null;
  return (
    <StyledNotification
      $backgroundColor={notificationData.notificationBackgroundColor}
      $color={notificationData.notificationTextColor}
    >
      <h2 className="notification__title">
        {notificationData.notificationTitle}
      </h2>
      <p className="notification__message">
        {notificationData.notificationText}
      </p>
    </StyledNotification>
  );
};

export default Notification;
