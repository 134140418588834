import React from "react";
import StyledHeader from "./Header.style";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../store/typedHooks";
import { setUser } from "../../store/userSlice";

const Header: React.FC = () => {
  const dispatch = useAppDispatch();

  const onClickExitLinkHandler = () => {
    dispatch(setUser({ username: "" }));
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
  };

  return (
    <StyledHeader>
      <nav className="header__nav">
        <Link className="header__link" to="/" title="Исходящие ссылки">
          Исходящие ссылки
        </Link>
        <Link
          className="header__link"
          to="/incoming-traffic-links"
          title="Входящие ссылки"
        >
          Входящие ссылки
        </Link>
        <Link className="header__link" to="/links-list" title="Список ссылок">
          Список активных ссылок
        </Link>
        <Link
          className="header__link"
          to="/generate-qr-code"
          title="Генерация QR кода"
        >
          Генерация QR кода
        </Link>
        <Link
          className="header__link"
          to="login"
          title="Выход"
          onClick={onClickExitLinkHandler}
        >
          Выход
        </Link>
      </nav>
    </StyledHeader>
  );
};

export default React.memo(Header);
