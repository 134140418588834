import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import linkReducer from "./linkSlice";
import notificationReducer from "./notificationSlice";

const rootReducer = combineReducers({
  user: userReducer,
  link: linkReducer,
  notification: notificationReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
