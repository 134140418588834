import { DefaultTheme } from "styled-components";

const baseTheme: DefaultTheme = {
  mediaMaxWidth1440: "1440px",
  mediaMaxWidth320: "320px",
  widthFor1440: "1280px",
  widthFor834: "807.5px",
  widthFor320: "290px",
  colorDarkGray: "#272727",
  colorLightGray: "#717171",
  colorPink: "#D32DBB",
  colorLight: "#F0F4EF",
  colorDarkTransparent: "#5c415817",
  notificationSuccsess: "#3C5453",
  notificationWarn: "#D4B52C",
  fontWeight400: 400,
  fontWeight600: 600,
  fontSize14: "14px",
  fontSize16: "16px",
  fontSize18: "18px",
  fontSize24: "24px",
  fontSize30: "30px",
  fontSize40: "40px",
  fontSize55: "53px",
  borderRadius: "60px",
  border: "2px solid #D32DBB",
  transitionStyle: "all 0.15s ease-in-out",
};
export default baseTheme;
