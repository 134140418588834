import styled from "styled-components";

type StyledProps = {
  $backgroundColor: string;
  $color: string;
};

const StyledNotification = styled.div<StyledProps>`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 300px;
  height: 150px;
  background-color: ${(props) => props.$backgroundColor};
  color: ${(props) => props.theme.colorLight};
  border-radius: ${(props) => props.theme.borderRadius};
  word-wrap: break-word;
  z-index: 999;
  padding: 30px;
  .notification__title {
    font-size: ${(props) => props.theme.fontSize24};
    margin-bottom: 5px;
  }
  .notification__message {
    font-size: ${(props) => props.theme.fontSize18};
  }
  @media (max-width: 980px) {
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100px;
    border-radius: 0;
    padding: 10px;
  }
`;

export default StyledNotification;
