import { createSelector } from "@reduxjs/toolkit";
import { RootState } from ".";
import { LinkInitialStateType } from "../types/linkType";

// Simple selectors

export const userNameSelector = (state: RootState) => state.user.username;

export const linksSelector = (state: RootState) => state.link.links;

export const selectedCounterSelector = (state: RootState) =>
  state.link.selectedCount;

export const selectLinkData = (state: RootState) => state.link;

export const selectPageNumber = (state: RootState) => state.link.page;

export const selectCategoryFilter = (state: RootState) =>
  state.link.categoryFilter;

export const selectNotification = (state: RootState) => state.notification;

// Create selector selectors

export const requestLinksDataSelector = createSelector(
  selectLinkData,
  (linkData: LinkInitialStateType) => ({
    source: linkData.categoryFilter,
    page: linkData.page,
  })
);

export const categoryFilterAndPageSelector = createSelector(
  selectCategoryFilter,
  selectPageNumber,
  (categoryFilter, pageNumber) => {
    return {
      categoryFilter,
      pageNumber,
    };
  }
);
