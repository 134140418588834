import paramsSerializer from "../services/paramsSerializer";
import {
  DeleteLinksRequestType,
  GetLinksListResponseType,
} from "../types/linkType";
import axiosIntsance from "./httpCommon";

type LinkGenerationRequestDataType = {
  linkName: string;
  tgChannelId: string;
  dealSource: string;
  googleSheetsLink: string;
  category: string;
};
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!fix naming get links
const getLInks = (categoryFIlter: string, pageNumber: number) => {
  return axiosIntsance.get<GetLinksListResponseType>(
    `/link/get-all-links?category=${categoryFIlter}&page=${pageNumber}`
  );
};

const searchLinks = (searchFieldData: string) => {
  const queryString = `/link/search-links?dealSource=${searchFieldData}`;
  return axiosIntsance.get(queryString);
};

const deleteLinks = (deleteLinksRequestData: DeleteLinksRequestType) => {
  const queryString = `/link/delete-selected-links?id=${deleteLinksRequestData.selectedLinksIds.join(
    "-"
  )}&category=${deleteLinksRequestData.categoryFIlter}&page=${
    deleteLinksRequestData.pageNumber
  }`;

  return axiosIntsance.delete<GetLinksListResponseType>(queryString);
};

const generateLink = (
  linkGenerationRequestData: LinkGenerationRequestDataType
) =>
  axiosIntsance.post<{ generatedLink: string }>(
    "link/create-link/",
    linkGenerationRequestData
  );

const linkRequests = {
  getLInks,
  searchLinks,
  generateLink,
  deleteLinks,
};

export default linkRequests;
