export type LinkType = {
  id: number;
  linkName: string;
  generatedLink: string;
  dealSource: string;
  googleSheetsLink: string;
  category: string;
  tgChannelId: string;
  selected?: boolean;
};

export type GetLinksListResponseType = {
  links: LinkType[];
  pagesCount: number;
  hasNext: boolean;
  hasPrevious: boolean;
  page: number;
};

export type DeleteLinksRequestType = {
  selectedLinksIds: number[];
  categoryFIlter: string;
  pageNumber: number;
};

export type LinkInitialStateType = {
  links: LinkType[];
  pagesCount: number;
  hasNext: boolean;
  hasPrevious: boolean;
  page: number;
  categoryFilter: string;
  selectedCount: number;
};

export enum CategoryEnum {
  clientToContractor = "От компании к подрядчику",
  clientToCompany = "От клиента в компанию",
  partnerToCompany = "От партнёра в компанию",
  advertisementCampaign = "Рекламная компания",
}
