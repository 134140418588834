import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { authorizationRequests } from "../api/authRequests";
import { UserType } from "../types/userType";

const initialState: UserType = {
  username: "",
};

// export const getUser = createAsyncThunk<
//   UserType,
//   undefined,
//   { rejectValue: Error | AxiosError }
// >("user/getUser", async (_, { rejectWithValue }) => {
//   try {
//     const response = await authorizationRequests.getUserData();
//     if (!response.data) {
//       return initialState;
//     }
//     return response.data;
//   } catch (error: any) {
//     console.log(error);
//     return rejectWithValue(error);
//   }
// });

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserType>) => {
      state.username = action.payload.username;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
