import React from "react";
import { Outlet } from "react-router";
import { createPortal } from "react-dom";
import StyledMainLayout from "./MainLayout.style";
import Header from "../../components/header/Header";
import Loader from "../../components/commonComponents/loader/Loader";
import baseTheme from "../../theme/baseTheme";
import Notification from "../../components/commonComponents/notifcation/Notification";

const MainLayout: React.FC = () => {
  return (
    <>
      <Notification />
      <StyledMainLayout>
        <Header />
        <main className="main-layout__outlet-container">
          <React.Suspense
            fallback={<Loader size={80} color={baseTheme.colorDarkGray} />}
          >
            <Outlet />
          </React.Suspense>
        </main>
      </StyledMainLayout>
    </>
  );
};

export default MainLayout;
